import React, { useContext } from 'react'
import styled from 'styled-components'
import { PageProps, graphql } from 'gatsby'

import FavouritesContext from 'contexts/FavouritesContext'

import Layout from 'components/layout/Layout'
import SEO from 'components/layout/SEO'
import Breadcrumbs from 'components/layout/Breadcrumbs'

import Container from 'components/shared/Container'
import { Heading, HighlightedText } from 'components/shared/Typography'

import Browser from 'components/layout/Flat/Browser'
import FlatForm from 'components/layout/Forms/FlatForm'

import type { WishlistPageQuery } from 'types/graphql'

import {
  FavouritesColumn,
  InvestmentColumn,
  NumberColumn,
  AreaColumn,
  FloorColumn,
  RoomsColumn,
  AdditionalAreaColumn,
} from 'constants/columns'

const Section = styled.section`
  padding: 4rem 0 2rem;
  min-height: calc(100vh - 300px);
  ${({ theme }) => theme.media.md.max} {
    padding: 3rem 0 0;
  }
`

const EmptyBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 300px;
`

const FormWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  min-width: 375px;
  margin-left: 1.5rem;
  flex: 1;
  ${({ theme }) => theme.media.md.max} {
    margin: 0 auto 2rem;
    min-width: initial;
  }
  ${({ theme }) => theme.media.xs.max} {
    margin: 0 auto;
  }
`

const WishlistPage: React.FC<PageProps<WishlistPageQuery>> = ({ data }) => {
  const PAGE = data?.page?.pageFavorite
  const PAGE_SEO = data?.page?.seo

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const { favourites } = useContext(FavouritesContext)

  return (
    <Layout background="white50">
      <SEO seo={PAGE_SEO} />
      <Breadcrumbs crumbs={[{ name: PAGE.favoritePageS1Title! }]} />
      <main>
        <Section>
          <Container>
            <Heading
              as="h3"
              size={50}
              sizeDiff={0.7}
              weight={500}
              align="center"
              margin="0"
              dangerouslySetInnerHTML={{ __html: PAGE.favoritePageS1Title! }}
            />
          </Container>
          {favourites.length > 0 ? (
            <Browser
              flats={favourites}
              columns={[
                InvestmentColumn,
                NumberColumn,
                AreaColumn,
                FloorColumn,
                RoomsColumn,
                AdditionalAreaColumn,
                FavouritesColumn,
              ]}
              tilesPerPage={{ xs: 4, sm: 6, md: 2, lg: 2, xl: 2, xxl: 3 }}
              tablePerPage={{ xs: 8, sm: 8, md: 6, lg: 6, xl: 6, xxl: 6 }}
              breakpoints={{ xs: 24, sm: 12, md: 24, lg: 12, xl: 12, xxl: 8 }}
            >
              <FormWrapper>
                <FlatForm
                  formID="wishlist-form"
                  heading={PAGE.favoritePageContactFormTitle!}
                  flat={favourites}
                  icon={PAGE.favoritePageContactFormIcon?.localFile?.publicURL!}
                  messageReadonly
                />
              </FormWrapper>
            </Browser>
          ) : (
            <Container>
              <EmptyBox>
                <HighlightedText>Brak mieszkań w schowku</HighlightedText>
              </EmptyBox>
            </Container>
          )}
        </Section>
      </main>
    </Layout>
  )
}

export default WishlistPage

export const query = graphql`
  query WishlistPage($locale: String) {
    page: wpPage(
      language: { slug: { eq: $locale } }
      slug: { regex: "/ulubione/" }
    ) {
      seo {
        ...WpSEO
      }
      pageFavorite {
        favoritePageS1Title
        favoritePageContactFormTitle
        favoritePageContactFormIcon {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
